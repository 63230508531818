import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="bg-black text-white  md:my-0 py-6 lg:py-">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center md:px-20">
      <div className="flex flex-col items-center md:items-start gap-4 mb-4 md:mb-0">
  {/* Social Media Section */}
  <div className="flex items-center gap-2">
    <h2 className="text-sm text-slate-200">Follow Us:</h2>
    <button
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/Instagram_Circle.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '25px',
       
        height: '25px',
        border: 'none',
        cursor: 'pointer',
        zIndex: 0,
      }}
      onClick={() => window.open('https://www.instagram.com/thereyansh.in', '_blank')}
      aria-label="Instagram Profile"
    ></button>
  </div>

  {/* Copyright Section */}
  <div className="mt-2 md:mt-0">
    <p className="text-xs text-slate-400 text-center md:text-left">
      &copy; {new Date().getFullYear()} Reyansh. All Rights Reserved.
    </p>
  </div>
</div>

        
        <div className=" flex space-x-6">
        <Link to="/our-team" className="text-slate-200 text-sm hover:underline">Our Team</Link>
          <Link to="/contact-us" className="text-slate-200 text-sm hover:underline">Contact Us</Link>
          
          
          <Link to="https://www.acharya.ac.in" className="text-slate-200 lg:text-sm text-sm hover:underline">acharya.ac.in</Link>
          
        </div>
      
        
      </div>
    </footer>
  );
};

export default Footer;
