// src/components/Card.js
import React from 'react';

export const Card = ({ className, children }) => (
  <div className={`bg-white shadow-md rounded-2xl pt-8 ${className}`}>{children}</div>
);

export const CardContent = ({ children }) => <div className="mt-4">{children}</div>;

export const CardHeader = ({ children }) => <div className="mb-4">{children}</div>;

export const CardTitle = ({ children }) => <h2 className="text-2xl mt-3 font-bold mb-8">{children}</h2>;

export const CardFooter = ({ children }) => <div className="">{children}</div>;