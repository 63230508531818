// // src/components/Navbar.js
// import React from 'react';
// import { Link } from 'react-router-dom';
// import './Navbar.css'; // Optional for custom styles

// const Navbar = () => {
//   return (
//     <nav className="navbar">
//       {/* Left Side: Logo */}
//       <div className="navbar-logo">
//         <Link to="/">
//           <img src="/images/reyansh-logo.png" alt="Reyansh Logo" className="logo" />
//         </Link>
//       </div>

//       {/* Middle: Links */}
//       <div className="navbar-links">
//         <Link to="/about-us" className="nav-link">About Us</Link>
//         <Link to="/our-team" className="nav-link">Our Team</Link>
//         <Link to="/contact-us" className="nav-link">Contact Us</Link>
//       </div>

//       {/* Right Side: Register Button */}
//       <div className="navbar-register">
//         <Link to="/register">
//           <button className="register-button">Register Now</button>
//         </Link>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;


// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gradient-to-b from-black/100 to-transparent text-white fixed top-0 left-0 w-full z-10 ">
      <div className="container mx-auto flex justify-between items-center p-6 md:p-10">
        <div className="navbar-logo md:ml-5">
          <Link to="/">
            <img src="/reyansh_website_logo.png" alt="Reyansh Logo" className="logo h-16 " />
          </Link>
        </div>
        <div className="bg-gray-200 rounded-lg py-2 hidden md:flex justify-center inline-flex px-16 space-x-12">
          <Link to="/events" className="text-base font-semibold hover:underline">
            Events
          </Link>
          <Link to="/our-team" className="text-base font-semibold hover:underline">
            Our Team
          </Link>
          <Link to="/about-us" className="text-base font-semibold hover:underline">
            About Us
          </Link>
        </div>
        {/* Register Now button visible only on desktop */}
        <Link
          to="/register"
          className="navbar_register_btn border-5 hidden mr-10 px-8 md:block bg-sky-600 hover:bg-sky-800 text-lg text-white font-bold py-3 px-4 rounded-lg"
        >
          Register Now
        </Link>
        {/* Hamburger Icon for Mobile View */}
        <button className="md:hidden" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-gray-900 text-white flex flex-col items-center justify-center space-y-4 transition-transform duration-500 ease-in-out transform ${
          isOpen ? "translate-y-0" : "-translate-y-full"
        } md:hidden z-50`}
      >
        {/* Cross Button */}
        <button
          onClick={toggleMenu}
          className="absolute top-4 right-6 text-white text-3xl focus:outline-none"
        >
          &times;
        </button>

        {/* Mobile Links */}
        <Link to="/events" className="text-2xl text-slate-200 hover:underline" onClick={toggleMenu}>
          Events
        </Link>
        <Link to="/our-team" className="text-2xl text-slate-200 hover:underline" onClick={toggleMenu}>
          Our Team
        </Link>
        <Link to="/about-us" className="text-2xl text-slate-200 hover:underline" onClick={toggleMenu}>
          About Us
        </Link>
        <Link to="/contact-us" className="text-2xl text-slate-200 hover:underline" onClick={toggleMenu}>
          Contact Us
        </Link>
        <Link
          to="/register"
          className="px-8 bg-sky-600 hover:bg-sky-800 text-white font-semibold py-2 rounded-full"
          onClick={toggleMenu}
        >
          Register Now
        </Link>
      </div>
    </nav>
  );
};


export default Navbar;






