import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404</h1>
      <p style={styles.message}>Page Not Found</p>
      <Link to="/" style={styles.link}>
        Go Back to <a className='text-sky-600 font-bold underline underline-offset-2 decoration-1'>Home</a>
      </Link>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f9fafb',
  },
  title: {
    fontSize: '72px',
    marginBottom: '16px',
    color: '#333',
  },
  message: {
    fontSize: '24px',
    marginBottom: '24px',
    color: '#555',
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    padding: '10px 20px',
    fontSize: '18px',
    backgroundColor: '#3e79b4',
    color: 'white',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
  },
};

export default NotFound;
