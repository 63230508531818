// src/pages/HomePage.js

import React, { useEffect,useRef, useCallback,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/Button'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight,faArrowRightToBracket} from '@fortawesome/free-solid-svg-icons'
import smoothscroll from 'smoothscroll-polyfill';


import Navbar from '../components/Navbar'; 
smoothscroll.polyfill();

const HomePage = () => {
    
  const navigate = useNavigate();
  const [contentLoaded, setContentLoaded] = useState(false);
    
  useEffect(() => {
    document.title = "Home - Reyansh"; // Set the title for Home Page
  }, []);
  useEffect(() => {
    // Optionally, force a reflow on mount
    void document.body.offsetHeight;
  }, []); // Run once on component mount

  const handleRegisterClick = () => {
    navigate('/register'); // Redirects to the register page
  };

  const handleTeamClick = () => {
    navigate('/our-team');
  };

  const handleAboutUsClick = () => {
    navigate('/about-us');
  };

  const handleContactUsClick = () => {
    navigate('/contact-us');
  };
  const targetRef = useRef(null);
  useEffect(() => {
    // Wait for images to load
    Promise.all(
      Array.from(document.images)
        .filter(img => !img.complete)
        .map(img => new Promise(resolve => {
          img.onload = img.onerror = resolve;
        }))
    ).then(() => {
      setContentLoaded(true);
    });
  }, []);
  const targetSectionRef = useRef(null);

  const scrollToContent = () => {
    if (targetRef.current) {
      // Scrolls the element into view with smooth scrolling
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  
  


  
  // return (
  //   <div className="flex flex-col items-center justify-center h-screen">
  //     <div className=' space-x-32 h-screen w-screen items-center flex flex-row' >
  //       <Navbar />
  //       <div className='px-8 w-2/6'>
  //         <h5 className="text-gray-100 text-sm mb-4">Welcome to Reyansh Fest!</h5>
  //       <p className="text-gray-100 font-semibold text-2xl mb-6">Join us in celebrating the richness of Hindi language and culture!</p>
  //       <Button onClick={handleRegisterClick}>Register Now</Button>
  //       </div>
        
  //     <div className=''> 
  //        <div className='h-fit px-10 py-3 m-24'>
  //         <img src='/website_card.png' alt="Reyansh Logo" className="logo" />
  //        </div>
  //     </div>
  //     {/* <Button onClick={handleRegisterClick}>Register Now</Button>
  //     <Button onClick={handleTeamClick} className="mt-4">Meet Our Team</Button>
  //     <Button onClick={handleAboutUsClick} className="mt-4">AboutUs</Button>
  //     <Button onClick={handleContactUsClick} className="mt-4">ContactUs</Button> */}
  //   </div>
  //   </div>
    
  // );
  return (
    
    <div className="  min-h-screen ">
      {/* Navbar at the top */}
      
  
      {/* Main content */}
      <div className="mt-20 p-8 first_section flex flex-col md:flex-row items-center justify-center w-full space-y-12 md:space-y-0 md:space-x-4 xl:space-x-0">
        
        {/* Left section: Text and Register button */}
        <div className="w-full  md:w-3/6 text-center md:text-left mx-10 md:px-16">
          <h5 className="text-gray-200 text-sm md:text-lg mb-4">
            Welcome to Reyansh!
          </h5>
          <p className="leading-10 text-gray-100 font-semibold text-3xl md:text-4xl mb-6">
          A celebration of <span className='italic font-anek-devanagari'>हिंदी,</span> <br></br><span >to make your talent <span className='font-source-serif-4 underline underline-offset-8 decoration-1'>shine!</span></span>
          </p>
          {/* <Button onClick={handleRegisterClick} className="home_register_btn bg-sky-600 text-white mt-5 px-8 py-2 font-semibold text-lg rounded-full hover:bg-sky-800">
          Get Reyansh e-Magazine <FontAwesomeIcon className='ml-1' icon={faArrowRight} />
          </Button> */}
          <Button onClick={() => targetSectionRef.current?.scrollIntoView({ behavior: 'smooth' })} className="home_register_btn bg-sky-600 text-white mt-5 px-8 py-2 font-semibold text-lg rounded-full hover:bg-sky-800">
          Explore Our Magazine <FontAwesomeIcon className='ml-1' icon={faArrowRight} />
          </Button>
        </div>
  
        {/* Right section: Image */}
        <div className="w-full md:w-3/6 flex justify-center">
          <div className="w-100 md:w-3/4 h-fit mr-6 py-3 mb-16">
            <img
              src="/images/home_main_card.svg"
              alt="Reyansh Logo"
              className="w-full h-auto object-contain"
            />
          </div>
        </div>
      </div>
      <div  ref={targetSectionRef} className="marquee-container">
      <div className="marquee">
        <span className='text-white'> ⚠️ <u>Important Update:</u> The dates for <strong>Reyansh Fest 2024</strong> have changed! 
        New dates are <strong>8th & 11th November 2024</strong>. Update your schedules and join us in the event! 👋🏻</span>
      </div>
    </div>
      <div  className='section-divider  h-1 w-full'></div>
      <div className='section3 relative bg-cover bg-no-repeat bg-bottom  w-full'>
        <div className='section3_vector absolute bg-top scale-100 z-0  md:top-0 w-full h-full mt-72  md:mt-0 md:h-full'>

        </div>
        <div className='section3-wrapper md:flex md:flex-row-reverse justify-end items-center gap-20 h-auto'>
          <div className='md:flex md:items-center md:w-7/12 md:mr-12 md:justify-items-center '>
          <div className=' magazine-detail-wrapper py-12 flex flex-col justify-items-center items-center  '>
          <div className="video-container z-0 px-5 flex md:w-4/6 align-center justify-center ">
  <video autoPlay muted loop width="100%" height="auto"  className='videocard rounded-2xl border-2 md:border-4'>
    <source src="/ReyanshPromo720pCompressed.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
              <div className='z-0 mt-6'>
                <h1 className='text-sm  lg:text-base text-white font-light text-center'>Witness our finest creation!</h1>
              </div>
              <div className='section3-magazine-main-heading -mt-4 z-0'>
              <div className=''>
                <h1 className='text-4xl text-white lg:text-5xl font-gloock-regular text-center'>Reyansh Magazine</h1>
              </div>
              <div className='section3-magazine-faded-divider invert ml-8 -my-3 md:ml-20 mb-0 h-2 w-60 '>
                
              </div>
              </div>
              <div className='mt-5 flex flex-col z-0 gap-2 justify-items-center items-center'>
              {/* <div className='launching_textbox bg-white shadow-md h-5 md:h-6 w-3/5'>
              <h1 className='text-xs font-medium md:text-sm text-center py-px'>Now Live!</h1>
              </div> */}
              <div className='launchingdate_textbox  bg-white shadow-lg h-10 py-2 md:py-1 rounded-lg'>
              <a
        href='https://drive.google.com/file/d/14kDbiVZ0XMloTv3Vj2lnH4wqfGY5sQok/view?usp=sharing'
        target="_blank"
        rel="noopener noreferrer"
        className="getmagazinebtn  text-md md:text-lg text-white  text-center font-bold px-py py-3 px-6"
      >Get Reyansh e-Magazine  <FontAwesomeIcon className='ml-1' icon={faArrowRightToBracket} rotation={90} /></a>
              {/* <h1 className=' text-lg md:text-lg text-white text-center font-bold px-py px-6'>Get e-Magazine  <FontAwesomeIcon className='ml-1' icon={faArrowRightToBracket} rotation={90} /></h1> */}
              </div>
              </div>
              
          </div>
          </div>
          <div className='z-0 md:h-96 md:mt-44   magazine-mockup h-96 w-full md:w-5/12'>
            
          </div>
          
        </div>
        
      </div>
      <div className='section-divider  h-1 w-full'></div>
      <div className='section2 bg-white w-full'>
          <div className='section2-wrapper py-12 px-10 flex flex-col gap-10 md:gap-32 md:flex-row md:justify-center'>
              <div className='day1-card  md:min-w-96 shadow-xl rounded-2xl '>
                <div className='day1-contents px-12 md:px-16 py-16 pb-16'>
                  <div className='day1-heading flex flex-col -space-y-4 '>
                    <div className='flex'>
                      <div className='star_card h-9 w-9 mr-2'></div>
                      <h1 className='text-white font-bold text-3xl'>DAY 1</h1></div>
                    
                    <h1 className='text-white font-light text-lg'>8th November, 2024</h1>
                  </div>
                  <div className='day1-allevents mt-5 flex flex-col gap-6'>
                  <div className='day1-event0 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Inauguration Ceremony &<br></br> Magazine Launch</h1>
                      <p className='text-white font-extralight text-xs -my-4'>09:00 AM to 11:00 AM</p>
                      </div>
                      
                    </div>

                    <div className='day1-event1 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Poster & Model Making</h1>
                      <p className='text-white font-extralight text-xs -my-4'>11:15 AM to 12:30 PM</p>
                      </div>
                      
                    </div>
                    {/* <div className='day1-event2 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Model Making</h1>
                      <p className='text-white font-extralight text-xs -my-4'>11:15 AM to 12:30 PM</p>
                      </div>
                      
                    </div> */}

                    <div className='day1-event3 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Extempore</h1>
                      <p className='text-white font-extralight text-xs -my-4'>11:45 AM to 12:45 AM</p>
                      </div>
                    </div>

                    <div className='day1-event4 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Emoji Paheli</h1>
                      <p className='text-white font-extralight text-xs -my-4'>01:30 PM to 2:30 PM</p>
                      </div>
                    </div>

                    <div className='day1-event5 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Shabd Antakshari</h1>
                      <p className='text-white font-extralight text-xs -my-4'>02:00 PM to 03:15 PM</p>
                      </div>
                    </div>

                    <div className='day1-event6 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Dictation Writing</h1>
                      <p className='text-white font-extralight text-xs -my-4'>02:30 PM to 03:30 PM</p>
                      </div>
                    </div>

                    

                    <div className='day1-event8 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Dumb Charades</h1>
                      <p className='text-white font-extralight text-xs -my-4'>03:30 PM to 04:45 PM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='day2-card md:min-w-96 shadow-xl rounded-2xl '>
              <div className='day2-contents px-12 md:px-16 py-16 pb-16'>
                  <div className='day2-heading flex flex-col -space-y-4 '>
                    <div className='flex'>
                    <div className='star_card h-9 w-9 mr-2'></div>
                    <h1 className='text-white font-bold text-3xl'>DAY 2</h1>
                    </div>
                    
                    <h1 className='text-white font-light text-lg'>11th November, 2024</h1>
                  </div>
                  <div className='day2-allevents mt-5 flex flex-col gap-6'>
                    <div className='day2-event1 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Logo Making</h1>
                      <p className='text-white font-extralight text-xs -my-4'>09:00 AM to 10:30 AM</p>
                      </div>
                      
                    </div>
                    

                    <div className='day2-event3 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Online Quiz</h1>
                      <p className='text-white font-extralight text-xs -my-4'>10:00 AM to 11:30 AM</p>
                      </div>
                    </div>

                    <div className='day2-event4 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Shabd Mahal</h1>
                      <p className='text-white font-extralight text-xs -my-4'>11:00 AM to 12:15 PM</p>
                      </div>
                    </div>

                    <div className='day2-event5 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Debate</h1>
                      <p className='text-white font-extralight text-xs -my-4'>01:00 PM to 02:45 PM</p>
                      </div>
                    </div>

                    

                    <div className='day2-event6 flex gap-2'>
                      <div className='arrow-pointer mt-1 h-3 w-3'></div>
                      <div>
                      <h1 className='text-white font-semibold text-base'>Prize Distribution Ceremony</h1>
                      <p className='text-white font-extralight text-xs -my-4'>03:30 PM to 04:45 PM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
          </div>
          <div className=' pb-10 w-full  text-center'>
          <a
        href='https://drive.google.com/file/d/1KRdzCfKLFQFCsN4UWTGU0rVNsAZ74Hyh/view?usp=sharing'
        target="_blank"
        rel="noopener noreferrer"
        className="brochure_btn bg-white border-2 border-slate-700 text-slate-700 font-bold hover:bg-slate-700 hover:text-slate-100 rounded-full px-5 py-2"
      >
        Get Brochure <FontAwesomeIcon className='ml-1' icon={faArrowRightToBracket} rotation={90} />
      </a>
          {/* <button className='bg-white border-2 border-slate-700 text-slate-700 font-bold rounded-full px-5 py-2'>Get Brochure <FontAwesomeIcon className='ml-1' icon={faArrowRight} /></button> */}
          </div>
          
      </div>
      
      
      <div className='section-divider z-50 h-1 w-full'></div>
      
<div className='section-divider z-50 h-1 w-full'></div>
      

    </div>
  );
  
  
};

export default HomePage;
