// EventList.js

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const events = [
  { id: 1, name: 'Poster Making', description: 'Let your imagination run wild—create vibrant posters that captivate minds and tell powerful stories!' },
  { id: 2, name: 'Model Making', description: 'Unleash your creativity and bring ideas to life—craft stunning models that speak louder than words!' },
  { id: 3, name: 'Extempore', description: 'Showcase your spontaneity and speaking skills by delivering impromptu speeches on exciting topics!' },
  { id: 4, name: 'Shabd Antakshari', description: 'Test your vocabulary and quick-thinking skills in this exciting wordplay challenge!' },
  { id: 5, name: 'Dictation Writing', description: 'Sharpen your listening & writing skills in this challenge of accuracy and demonstrate your command over Hindi spelling & comprehension! ' },
  { id: 6, name: 'Dumb Charades', description: "Put your acting skills to the test in this thrilling game of charades!" },
  { id: 7, name: 'Logo Making', description: 'Unleash your creativity and design skills by crafting a unique logo that captures the essence of Reyansh Fest!' },
  { id: 8, name: 'Online Quiz', description: 'Put your knowledge to the test with our fun and interactive online quiz!' },
  { id: 9, name: 'Shabd Mahal', description: 'Step into the world of words with our Shabd Mahal competition!' },

  { id: 10, name: 'Emoji Puzzle', description: 'Decode the meaning behind a series of emojis in this fun and brain-teasing challenge' },
  { id: 11, name: 'Debate', description: 'Engage in a spirited exchange of ideas and opinions at our debate competition while also enhancing your public speaking skills!' },
  
 
  // Add more events as needed
];

const EventList = () => {
  useEffect(() => {
    document.title = "Events - Reyansh"; // Set the title for Home Page
  }, []);
    return (
        <div className='mx-12'>
          <div className='flex flex-row justify-center align-center mb-5 mt-20'>
            <h1 className='text-white text-3xl font-semibold underline underline-offset-8 decoration-2'>Events</h1>
          </div>
          <div className="my-10 mx-auto xl:mx-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-20 gap-12">
  {events.map((event) => (
    <div key={event.id} className="bg-white shadow-lg rounded-lg p-6 flex flex-col">
      {/* Event Image */}
      <h2 className="text-xl font-semibold mb-2">{event.name}</h2>
      <p className="text-sm text-gray-600 flex-grow">{event.description}</p>
      <Link to={`/events/${event.id}`} className="mt-4">
        <div className='viewdetails_btn bg-transparent border border-sky-600 shadow-md hover:bg-sky-600 rounded-lg px-3 py-1 hover:text-slate-200 text-center text-sky-600'>
          View Details
        </div>
      </Link>
    </div>
  ))}
</div>

        </div>
      );
      
};

export default EventList;
