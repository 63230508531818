// src/App.js


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/Homepage';
import RegistrationForm from './pages/registration_page'; // Adjust the import path as needed
import OurTeam from './pages/OurTeam';
import AboutUs from './pages/AboutUs';  
import ContactUs from './pages/ContactUs'; 
import EventList from './pages/EventList';
import AdminPage from './pages/AdminPage';
import AdminPageLogin from './pages/AdminPageLogin';
import WhatsApp_info from './pages/Whatsapp_info';
import Error404 from './pages/Error404';
import smoothscroll from 'smoothscroll-polyfill';


import EventDetails from './pages/EventDetails';

import { inject } from '@vercel/analytics';
smoothscroll.polyfill();

inject(); // Inject the analytics tracking code


// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<HomePage />} />
//         <Route path="/register" element={<RegistrationForm />} />
//         <Route path="/our-team" element={<OurTeam />} />
//         <Route path="/about-us" element={<AboutUs/>} />
//         <Route path="/contact-us" element={<ContactUs/>} />
//       </Routes>
//     </Router>
//   );
// };

const App = () => {
  return (
    <Router>
       <Routes>
        <Route path="/" element={<Layout />}>
        <Route path="/" element={<HomePage />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="our-team" element={<OurTeam />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="register" element={<RegistrationForm />} />
          <Route path="/events" element={<EventList />} />
          <Route path="/admin" element={<AdminPage/>} />
          <Route path="/success/join-whatsapp" element={<WhatsApp_info />} />
          <Route path="/admin/login" element={<AdminPageLogin />} />
          {/* <Route path="/admin/dashboard" element={<AdminDashboard />} /> */}
        <Route path="/events/:id" element={<EventDetails />} />
        <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;

