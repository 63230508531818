import React from 'react';

const WhatsApp_info = () => {
  return (
    <div className="flex mt-40 justify-center items-center max-h-screen">
      <div className="text-center bg-white p-8 mx-10 shadow-lg rounded-lg max-w-md">
        <h2 className="text-2xl font-bold mb-6">Thank You for Registering!</h2>
        <div className="mb-8 text-sm text-gray-700">Click the link below to join our <span className='font-bold'>WhatsApp group</span> for further updates:</div>
        {/* <p className="mb-4 text-sm text-gray-700">
          Click the link below to join our <span className='font-bold'>WhatsApp group</span> for further updates:
        </p> */}
        <a
          href="https://chat.whatsapp.com/GP3qAkkLB6i6US1Guy5RTq"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
        >
          Join WhatsApp Group
        </a>
      </div>
    </div>
  );
};

export default WhatsApp_info;
