// src/pages/OurTeam.js
import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent, CardFooter } from '../components/Card'; // Adjust the import path as needed

import deepak_image from '../images/team_members/deepak.webp';
import principal_image from '../images/team_members/principal.png';
import dean_image from '../images/team_members/dean_sir.webp';
import pramod_image from '../images/team_members/pramod_sir.jpg';
import yunus_image from '../images/team_members/yunus_sir.webp';
import harshwardhan_image from '../images/team_members/harshwardhan.png';

const teamMembers = [
  {
    name: 'Dr. Gurunath Rao Vaidya',
    role: 'Principal, AIGS',
    description: (<>Under the visionary leadership of our Principal, Reyansh Club has flourished, fostering a vibrant community that celebrates the beauty of the Hindi language and culture. His guidance has been instrumental in making the club more active, encouraging greater participation, and fostering seamless coordination among members, thus enriching our activities & events.</>),
    imageUrl: principal_image, 
  },
  {
    name: 'Prof. Gururaj S.',
    role: 'Dean, AIGS',
    description: (<>Dean Sir has been a strong supporter of Reyansh Club,  providing invaluable support and expertise that have significantly contributed to our growth. His encouragement and guidance inspire us to deepen our appreciation for the Hindi language and culture, making our journey enjoyable & enriching.</>),
    imageUrl: dean_image,
  },
  {
    name: 'Dr. Pramod Nag',
    role: (<>Head, Dept. of Hindi<br></br> AIGS</>),
    description: (<>Pramod Sir has been a wonderful mentor for the Hindi Reyansh Club. As the Head of the Hindi Department, his love for Hindi, constant support, insightful ideas, and suggestions have been instrumental in the growth and success of our club. His guidance has helped create a lively and supportive community, and we are deeply thankful for his invaluable contributions to the development of 'Reyansh</>),
    imageUrl: pramod_image,
  },
  {
    name: 'Prof. Yunus Ali',
    role: (<>Assistant Professor, Dept. of Hindi<br></br> AIGS</>),
    description: (<>Yunus Sir has been a vital part of the Reyansh Club. His dedication in refining every aspect of 'Reyansh' has been commendable. He has provided invaluable mentorship, offering ideas and suggestions that have greatly contributed to the club's success. We are deeply grateful for his guidance & support.</>),
    imageUrl: yunus_image,
  }
  // Add more team members as needed
];

const teamMembers2 = [
  {
    name: 'Deepak Choudhary',
    role: (<>Head Coordinator<br></br>BCA, 5th Sem</>),
    description: (<>This website has been entirely designed, created, and deployed by Deepak, the main coordinator of the Hindi Reyansh Club. His effort and dedication are evident in every aspect of this project, from designing the complete Reyansh Magazine 2024-25 to implementing innovative ideas that have elevated the club to new heights.</>),
    imageUrl: deepak_image, // Replace with actual images
  },
  {
    name: 'Harshvardhan Molleti',
    role: (<>Head Event Coordinator<br></br>BCA, 5th Sem</>),
    description: (<>Harshvardhan had also played a key role in the Reyansh Club by serving as the main coordinator, overseeing event organization & ensuring smooth execution of club activities. Additionally, contributing significantly to the editing & correction of articles for the Reyansh magazine to ensure proper structure & readability.</>),
    imageUrl: harshwardhan_image,
  },
  
  
  // Add more team members as needed
];

const OurTeam = () => {

  useEffect(() => {
    document.title = "Our Team - Reyansh"; // Set the title for Home Page
  }, []);
  return (
    <div className="container mx-auto mt-24">
      <h1 className="text-3xl underline underline-offset-8 decoration-2 text-slate-100 font-bold text-center ">Our Team</h1>
      <div className="flex flex-wrap justify-center gap-10 my-12 sm:mx-20 sm:mb-16">
        {teamMembers.map((member, index) => (
          <Card key={index} className=" max-w-xs h-full p-0 h-full p-0 flex flex-col">
            <CardHeader>
              <img src={member.imageUrl} alt={`${member.name}`} className="object-cover  shadow-lg rounded-full h-32 w-32 mx-auto" />
              </CardHeader>
              <div className='bg-sky-100 rounded-2xl p-8 w-full'>
              <h2 className="text-lg font-bold text-center mt-4">{member.name}</h2>
              <h3 className="text-sm text-center text-gray-500">{member.role}</h3>
            
            
              <p className="pt-5 text-xs leading-5  md:text-sm text-center">{member.description}</p>
            
              </div>
              
            <CardFooter className="text-center">
              {/* Optionally add social links or contact details */}
            </CardFooter>
          </Card>
        ))}
      </div>
      <h1 className="text-3xl underline underline-offset-8 decoration-2 text-slate-100 font-bold text-center mb-12">Our Student Team</h1>
      <div className="flex flex-wrap justify-center gap-10 my-12 sm:mx-20 sm:mb-16">
        {teamMembers2.map((member, index) => (
          <Card key={index} className="max-w-xs h-full p-0 h-full p-0 flex flex-col">
            <CardHeader>
              <img src={member.imageUrl} alt={`${member.name}`} className="object-cover shadow-lg rounded-full h-32 w-32 mx-auto" />
              </CardHeader>
              <div className='bg-sky-100 rounded-2xl p-8 w-full'>
              <h2 className="text-lg font-bold text-center mt-4">{member.name}</h2>
              <h3 className="text-sm text-center text-gray-500">{member.role}</h3>
            
            
              <p className="pt-5 text-xs leading-5  md:text-sm text-center">{member.description}</p>
              </div>
            <CardFooter className="text-center">
              {/* Optionally add social links or contact details */}
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
    
  );
};

export default OurTeam;
