import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const backendUrl = process.env.REACT_APP_REYANSH_BACKEND_URL;

const AdminPage = () => {
  const [registrations, setRegistrations] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);

  const LoadingScreen = () => {
    return (
      <div className="loading-screen">
        <div className="spinner"></div> 
        <p>Logging In...</p> 
      </div>
    );
  };

  // Handle admin login
  const handleLogin = async (e) => {
    e.preventDefault();
    setloading(true);
    const storedToken = localStorage.getItem('token');
  
/*
    if (storedToken) {
      try {
        // Send the token to the backend for validation
        const tokenValidationResponse = await axios.get(`${backendUrl}/admin/validate-token`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
  
        if (tokenValidationResponse.status === 200) {
          // Token is valid, set authentication state and fetch registrations
          setIsAuthenticated(true);
          fetchRegistrations();
          return;
        }
      } catch (error) {
        console.error('Token validation failed, proceeding with login', error);
        localStorage.removeItem('token'); // Remove invalid token
      }
    }
*/


    try {
      const response = await axios.post(`${backendUrl}/api/admin/login`, { username, password },{ withCredentials: true});
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        
        setIsAuthenticated(true);
        setloading(false)
        navigate('/admin');
        // fetchRegistrations(); // Fetch registrations after successful login
      }
    } catch (error) {
      setloading(false);
      console.error('Login failed', error);
      alert('Login failed: Invalid credentials or Too many Attempts. Please try again after some time.');
    }
  };

  // Fetch all registrations
  // const fetchRegistrations = async () => {
  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     try {
  //       const response = await axios.get(`${backendUrl}/admin/registrations`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`  // Send token in Authorization header
  //         }
  //       });
  //       console.log('Protected data:', response.data);
  //       setRegistrations(response.data)
  //     } catch (error) {
  //       console.error('Error fetching protected data', error);
  //     }
  //   } else {
  //     console.log('No token found');
  //   }
  // };

  return (
    <div className="p-6">
      {!isAuthenticated ? (
        <form onSubmit={handleLogin}>
          <div className='m-4 lg:m-20'>
          <h2 className="text-white text-2xl font-bold mb-4 mt-16 ">Admin Login</h2>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="border p-2 mb-2 w-full"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="border p-2 mb-4 w-full"
          />
          <button type="submit" className="bg-blue-500 text-white p-2">Login</button>
          </div>
          {loading && <LoadingScreen />}
        </form>
      ) : (
        <>
  <h2 className="text-white text-2xl font-bold mb-4">User Registrations</h2>
  <div className="overflow-x-auto" style={{  overflowY: 'auto' }}>
    <table className="min-w-full bg-white border-collapse border border-gray-200 " >
      <thead>
        <tr className="bg-gray-200">
          <th className="border border-gray-200 p-2">Name</th>
          <th className="border border-gray-200 p-2">Email</th>
          <th className="border border-gray-200 p-2">USN</th>
          <th className="border border-gray-200 p-2">AUID</th>
          <th className="border border-gray-200 p-2">Course</th>
          <th className="border border-gray-200 p-2">Semester</th>
          <th className="border border-gray-200 p-2">Phone Number</th>
          <th className="border border-gray-200 p-2">Team Name</th>
          <th className="border border-gray-200 p-2">Team Size</th>
          <th className="border border-gray-200 p-2">Team Members</th>
          <th className="border border-gray-200 p-2">Day 1 Events</th>
          <th className="border border-gray-200 p-2">Day 2 Events</th>
        </tr>
      </thead>
      <tbody>
        {registrations.map((registration) => (
          <tr key={registration._id}>
            <td className="border border-gray-200 p-2">{registration.name}</td>
            <td className="border border-gray-200 p-2">{registration.email}</td>
            <td className="border border-gray-200 p-2">{registration.usn}</td>
            <td className="border border-gray-200 p-2">{registration.auid}</td>
            <td className="border border-gray-200 p-2">{registration.course}</td>
            <td className="border border-gray-200 p-2">{registration.semester}</td>
            <td className="border border-gray-200 p-2">{registration.phoneNumber}</td>
            <td className="border border-gray-200 p-2">{registration.teamName}</td>
            <td className="border border-gray-200 p-2">{registration.teamSize}</td>
            <td className="border border-gray-200 p-2">
              <ul>
                {registration.teamMembers.map((member, index) => (
                  <li key={index}>
                    {index + 1}. {member.name} ({member.phone}, {member.usn})
                  </li>
                ))}
              </ul>
            </td>
            <td className="border border-gray-200 p-2">
              <ul>
                {registration.day1Events.map((event, index) => (
                  <li key={index}>{event}</li>
                ))}
              </ul>
            </td>
            <td className="border border-gray-200 p-2">
              <ul>
                {registration.day2Events.map((event, index) => (
                  <li key={index}>{event}</li>
                ))}
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</>

      )}
    </div>
  );
};

export default AdminPage;
