import React, { useEffect } from 'react';

const AboutUs = () => {

  useEffect(() => {
    document.title = "About Us - Reyansh"; // Set the title for Home Page
  }, []);

  return (
    <div className="text-slate-100 about-us-container">
      <h1 className='font-semibold underline underline-offset-8 decoration-2 mb-12'>About Reyansh</h1>
      <div className='text-slate-200'>
        <p className='text-lg lg:text-xl font-anek-devanagari font-bold'>
        रेयांश - 'सूर्य की पहली किरण'
        </p>
      <p className='text-sm lg:text-base'>
      Founded in 2018, Reyansh, the Hindi club of Acharya Institute of Graduate Studies, celebrates the beauty of the Hindi language and culture. Our mission is to cultivate a deeper appreciation for Hindi literature, sharpen language skills, and build a vibrant community of passionate Hindi enthusiasts.
      </p>
      <p className='text-sm lg:text-base'>
      Through the years, we’ve organized a variety of impactful events—from poetry recitations and debates to grand cultural fests—leaving a lasting imprint on our campus. These efforts reflect our commitment to preserving and promoting the richness of Hindi. At Reyansh, we believe in growth through creativity and innovation while conveying deeper level of storytelling and expression.
      </p>
      
      <p className='text-sm lg:text-base'>
      Join us on this exciting journey as we continue to explore, engage, and celebrate every moment!
      </p>
      </div>
    </div>
  );
};

export default AboutUs;
