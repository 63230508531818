// src/components/Dropdown.js
import React from 'react';

export const Dropdown = ({ label, options, value, onChange, name, className, required }) => (
  <div className={`dropdown-wrapper ${className}`}>
    <label htmlFor={name}>{label}</label>
    <select
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      className="form-select mt-1 block w-full"
    >
      <option value="">Select {label}</option>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);
