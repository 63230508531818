// import React, { useState } from 'react';
// import { Card, CardContent, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
// import { Button } from '@/components/ui/button';
// import { Input } from '@/components/ui/input';
// import { Checkbox } from '@/components/ui/checkbox';
// import { Label } from '@/components/ui/label';

import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from '../components/Card';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { Checkbox } from '../components/Checkbox';
import { Label } from '../components/Label';
import { Dropdown } from '../components/Dropdown';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight,faMinus,faPlus} from '@fortawesome/free-solid-svg-icons'


const backendUrl = process.env.REACT_APP_REYANSH_BACKEND_URL;

const RegistrationForm = () => {
  useEffect(() => {
    document.title = "Register | Reyansh"; // Set the title for Home Page
  }, []);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    usn: '',
    auid: '',
    course: '',
    semester: '',
    phoneNumber: '',
    day1Events: [],
    day2Events: [],
    teamName: '',
    teamSize: '',
    teamMembers: [],
  });

  const day1Events = ['Poster Making','Model Making','Extempore', 'Shabd Antakshari', 'Dictation Writing','Dumb Charades'];
  const day2Events = ['Logo Making', 'Online Quiz','Shabd Mahal','Emoji Paheli','Debate',];
  const teamEvents = ['Poster Making','Model Making','Shabd Antakshari','Dumb Charades','Emoji Paheli', 'Debate']; // Events that require a team

  const courses = ['BCA', 'BBA','BBA Aviation', 'B.Com', 'B.A','B.S.W', 'B.Sc', 'M.A', 'M.Com', 'M.Sc', 'M.S.W', 'M.C.A','M.B.A'];
  const semesters = ['1st', '2nd', '3rd', '4th', '5th', '6th',];

 
  const validateTeamDetails = () => {

    const needsTeam = formData.day1Events.some(event => teamEvents.includes(event)) ||
                          formData.day2Events.some(event => teamEvents.includes(event));
    if(needsTeam){
      if (!formData.teamName) {
        alert('Please fill in the team name.');
        return false;
    }
    if (!formData.teamSize || formData.teamSize < 2) {
        alert('Please specify a valid team size (at least 2 members).');
        return false;
    }
    if (!formData.teamSize || formData.teamSize >4 ) {
      alert("You can't select a team size of more than 4.");
      return false;
  }
    if (formData.teamMembers.length < formData.teamSize) {
      alert('Please add all team members. You specified ' + formData.teamSize + ' members.');
      return false;
    }
    for (const member of formData.teamMembers) {
      if (!member.name || !member.phone || !member.usn) {
        alert('Please fill in all team member details.');
        return false;
      }
    }
    }


    
    return true;
};


  const validateEmail =(email)=>{
      
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
      
  }

  const validatePhone = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Adjust this as needed
    return phoneRegex.test(phone);
  };

  const validateAuid = (auid) => {
    const auidRegex = /^AGS.*/i;
    return auidRegex.test(auid);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEventChange = (day, event) => {
    setFormData((prevData) => {
      const eventList = prevData[day];
      
      // Check if the event is already selected
      if (eventList.includes(event)) {
        // Deselect the event (remove it from the list)
        const updatedList = eventList.filter((e) => e !== event);
        return { ...prevData, [day]: updatedList };
      } else {
        // Ensure no more than 4 events are selected (across both days)
        const totalSelectedEvents = prevData.day1Events.length + prevData.day2Events.length;
        
        if (totalSelectedEvents >= 8) {
          alert('You can select a maximum of 8 events in total.');
          return prevData; // Prevent adding the event if limit reached
        }
        // Add the event to the list
        return { ...prevData, [day]: [...eventList, event] };
      }
    });
  };
  

  const handleTeamMemberChange = (index, field, value) => {
    setFormData((prevData) => {
      const updatedMembers = [...prevData.teamMembers];
      updatedMembers[index] = { ...updatedMembers[index], [field]: value };
      return { ...prevData, teamMembers: updatedMembers };
    });
  };

  const addTeamMember = () => {
    if (formData.teamMembers.length >= formData.teamSize) {
      alert('You can only add up to ' + formData.teamSize + ' members.');
      return;
  }
  if (formData.teamMembers.length >= 4) {
    alert('You can only add max 4 members.');
    return;
}
    setFormData((prevData) => ({
      ...prevData,
      teamMembers: [...prevData.teamMembers, { name: '', phone: '', usn: '' }],
    }));
  };

  const removeTeamMember = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      teamMembers: prevData.teamMembers.filter((_, i) => i !== index),
    }));
  };

  const nextStep = () => {
    // Step 1 validation: Check if all required fields are filled
    if (step === 1) {
      const { name, email, usn,course,semester, auid, phoneNumber } = formData;
  
      if (!name || !email || !auid || !course || !semester || !phoneNumber) {
        alert('Please fill out all required fields before proceeding.');
        return; // Prevent moving to the next step if any field is empty
      }
      if (!validateEmail(email)){
          alert("Please Enter your Valid Email.")
          return;
      }
      if (!validateAuid(auid)){
        alert("Please Enter your valid AUID.")
        return;
    }
      if (!validatePhone(phoneNumber)){
        alert("Please Enter your valid Phone Number.")
        return;
    }
    
    
    }
    if (step === 2) {
      const selectedDay1Events = formData.day1Events.length > 0;
  const selectedDay2Events = formData.day2Events.length > 0;

  // If no event is selected, show an alert
  if (!selectedDay1Events && !selectedDay2Events) {
    alert('Please select at least one event from Day 1 or Day 2.');
    return; // Stop the submission
  }
    }

    
  
    // If validation passes, move to the next step
    setStep(step + 1);
  };
  const prevStep = () => setStep(step - 1);

  const [loading, setLoading] = useState(false); 

  const navigate = useNavigate();



  
  const handleSubmit = async (e) => {
    

    
    e.preventDefault();
    setLoading(true);
    if (step === 3 && !validateTeamDetails()) {
      setLoading(false);
      return;
    }
    try {
      const response = await fetch(`${backendUrl}/api/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setLoading(false);
        alert('Registration successful!');
        setFormData({
          name: '',
          email: '',
          usn: '',
          auid: '',
          course: '', 
          semester: '',
          phoneNumber: '',
          day1Events: [],
          day2Events: [],
          teamName: '',
          teamSize: '',
          teamMembers: [],
        });
        setStep(1);
        navigate('/success/join-whatsapp');
      } else {
        setLoading(false);
        alert('Registration failed. Please try again.');
      }
    } catch (error) {
      setLoading(false); 
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again later.');
    }

    


  };

  const LoadingScreen = () => {
    return (
      <div className="loading-screen">
        <div className="spinner"></div> 
        <p>Registering...</p> 
      </div>
    );
  };

  const TwoMemberEventLabel = () => {
    return(
      <h3>You Selected Event that requires 2 Members thus please provide details for 2 Members only.</h3>
    );
  }

  const Two_with_Four_Eventslabel = ()=>{
    return(
      <h3>Some events require 2 members, while others need 4. Please provide details for all 4 members;<br></br> <b>the first two will automatically be considered for 2-member events.</b><br></br>Thus please fill accordingly.</h3>
    );
  }
  
  const FourMemberEventLabel = () => {
    return(
      <h3>You Selected Event that requires 4 Members thus please provide details for 4 Members only.</h3>
    );
  }



  const renderStep = () => {
    
    switch (step) {
      case 1:
        return (
          <>
            <div className="form-group">
              <Label
                htmlFor="name"
                className=" w-full text-base block text- font-bold text-gray-700 mb-1"
              >
                Full Name <span className="text-red-500">*</span>
              </Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your full name"
                required
                className="input-field w-full px-3 w-full py-2 border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-sky-600 "
              />
            </div>
            <div className="form-group">
              <Label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Email <span className="text-red-500">*</span>
              </Label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                required
                className="input-field w-full px-3 py-2 border border-gray-300 rounded-md shadow-md focus:outline-none focus:ring-sky-600 "
              />
            </div>
            <div className="form-group">
              <Label
                htmlFor="usn"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                USN
              </Label>
              <Input
                type="text"
                name="usn"
                value={formData.usn}
                onChange={handleInputChange}
                placeholder="Enter your USN"
                required
                className="input-field w-full px-3 py-2 border border-gray-300 rounded-md shadow-md focus:outline-none  focus:ring-sky-600"
              />
            </div>
            <div className="form-group">
              <Label
                htmlFor="auid"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                AUID <span className="text-red-500">*</span>
              </Label>
              <Input
                type="text"
                name="auid"
                value={formData.auid}
                onChange={handleInputChange}
                placeholder="Enter your AUID"
                required="required"
                className="input-field w-full px-3 py-2 border border-gray-300 rounded-md shadow-md focus:outline-none  focus:ring-sky-600"
              />
            </div>
            <div className="form-group">
              <Label
                htmlFor="course"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Course <span className="text-red-500">*</span>
              </Label>
              <Dropdown
                name="course"
                options={courses}
                value={formData.course}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-md focus:outline-none  focus:ring-sky-600"
              />
            </div>
            <div className="form-group">
              <Label
                htmlFor="semester"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Semester <span className="text-red-500">*</span>
              </Label>
              <Dropdown
                name="semester"
                options={semesters}
                value={formData.semester}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-md focus:outline-none  focus:ring-sky-600"
              />
            </div>
            <div className="form-group">
              <Label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Phone Number <span className="text-red-500">*</span>
              </Label>
              <Input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
                required
                className="input-field w-full px-3 py-2 mb-10 border border-gray-300 rounded-md shadow-md focus:outline-none  focus:ring-sky-600"
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h3 className="mb-5 text-lg  font-semibold ">Day 1 Events:</h3>
            {day1Events.map((event) => (
              <div key={event} className="flex items-center space-x-2">
                <Checkbox
                  id={`day1-${event}`}
                  checked={formData.day1Events.includes(event)}
                  onChange={() => handleEventChange("day1Events", event)}
                  disabled={event === "Poster Making" || event ==='Model Making' || event ==='Shabd Antakshari' || event ==='Dictation Writing' || event ==='Dumb Charades' || event ==='Extempore'}
                />
                <Label htmlFor={`day1-${event}`}>
                  {event}
                  {(
                    event === "DumCharades" ||event ==='Shab Antakshari') && (
                    <span className={`text-sky-600`}>
                      {event === "Shabd Antakshari" || event === "Poster Making"
                        ? " (2 Team Members)"
                        : " (4 Team Members)"}
                    </span>
                  )}
                  {event === "Poster Making" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  {event === "Shabd Antakshari" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  {event === "Model Making" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  {event === "Dictation Writing" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  {event === "Dumb Charades" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  {event === "Extempore" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  
                  
                </Label>
              </div>
            ))}
            <h3 className="pt-5 pb-2 text-lg font-semibold ">Day 2 Events:</h3>
            {day2Events.map((event) => (
              <div key={event} className="flex items-center space-x-2">
                <Checkbox
                  id={`day2-${event}`}
                  checked={formData.day2Events.includes(event)}
                  onChange={() => handleEventChange("day2Events", event)}
                  disabled={event === 'Logo Making' || event === 'Online Quiz' || event === 'Shabd Mahal' || event === 'Emoji Paheli' || event === 'Debate'}
                />
                <Label htmlFor={`day2-${event}`}>
                  {event}
                  {(event === "Emoji aheli" || event === "Dbate") && (
                    <span className="text-sky-600"> (2 Team Members)</span>
                  )}
                  {event === "Logo Making" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  {event === "Online Quiz" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  {event === "Shabd Mahal" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  {event === "Emoji Paheli" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  {event === "Debate" && (
                    <span className={`text-red-600`}> (Event Full - Registrations Closed)</span>
                  )}
                  
                </Label>
              </div>
            ))}
          </>
        );
      case 3:
        const needsTeam =
          formData.day1Events.some((event) => teamEvents.includes(event)) ||
          formData.day2Events.some((event) => teamEvents.includes(event));
        if (!needsTeam) {
          return (
            <p>No team events selected. You can submit your registration.</p>
          );
        }

        const ShabdAntakshari_check =
          formData.day1Events.includes("Shabd Antakshari");
        const PosterMaking_check =
          formData.day1Events.includes("Poster Making");
        const ModelMaking_check = formData.day1Events.includes("Model Making");
        const DumbCharades_check =
          formData.day1Events.includes("Dumb Charades");
        const EmojiPaheli_check = formData.day2Events.includes("Emoji Paheli");
        const Debate_check = formData.day2Events.includes("Debate");
        const TwoMemberEventCheck =
          ShabdAntakshari_check ||
          EmojiPaheli_check ||
          Debate_check ||
          PosterMaking_check;
        const FourMemberEventCheck = DumbCharades_check || ModelMaking_check;
        const Two_and_Four_member_check =
          TwoMemberEventCheck && FourMemberEventCheck;

        return (
          <>
            {TwoMemberEventCheck && FourMemberEventCheck ? (
              <Two_with_Four_Eventslabel />
            ) : TwoMemberEventCheck ? (
              <TwoMemberEventLabel />
            ) : FourMemberEventCheck ? (
              <FourMemberEventLabel />
            ) : null}
            <Input
              type="text"
              name="teamName"
              value={formData.teamName}
              onChange={handleInputChange}
              placeholder="Team Name"
              required
            />
            <Input
              type="number"
              name="teamSize"
              value={formData.teamSize}
              onChange={handleInputChange}
              placeholder="Number of Team Members"
              required
              min="2"
              max="4"
            />
            {formData.teamMembers.map((member, index) => (
              <div key={index} className="mt-4">
                <h4>Team Member {index + 1}</h4>
                <Input
                  className="mt-2 mb-2"
                  type="text"
                  value={member.name}
                  onChange={(e) =>
                    handleTeamMemberChange(index, "name", e.target.value)
                  }
                  placeholder="Name"
                  required
                />
                <Input
                  type="number"
                  className="mt-2 mb-2"
                  value={member.phone}
                  onChange={(e) =>
                    handleTeamMemberChange(index, "phone", e.target.value)
                  }
                  placeholder="Phone No."
                  required
                />
                <Input
                  type="text"
                  className="mt-2 mb-2"
                  value={member.usn}
                  onChange={(e) =>
                    handleTeamMemberChange(index, "usn", e.target.value)
                  }
                  placeholder="USN/AUID"
                  required
                />
                <Button
                  className="removeTeamMember_btn text-sm mt-2 bg-white text-red-700 border-2 border-red-700 hover:bg-red-700 hover:text-white"
                  type="button"
                  onClick={() => removeTeamMember(index)}
                >
                  <FontAwesomeIcon className="mr-1" icon={faMinus} /> Remove
                  Member
                </Button>
              </div>
            ))}
            <Button
              type="button"
              className="addTeamMember_btn text-sm font-medium text-white border-sky-600 py-3 hover:bg-sky-800 hover:text-white"
              onClick={addTeamMember}
            >
              <FontAwesomeIcon className="mr-1" icon={faPlus} /> Add Member
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  

  return (
    
    <Card className="my-32 w-full p-8 max-w-lg mx-auto">
      <CardHeader>
        <CardTitle className=''>Reyansh Fest Registration - Step {step}</CardTitle>
      </CardHeader>
      <CardContent>
      {loading && <LoadingScreen />}
        <form onSubmit={handleSubmit} className="space-y-4">
          {renderStep()}
        </form>
      </CardContent>
      <CardFooter className="flex justify-between">
        {step > 1 && <Button className='previous_btn mr-3  mt-10 bg-white text-sky-700 border-2 border-sky-700 hover:bg-slate-900 hover:text-white' onClick={prevStep}>Previous</Button>}
        {step < 3 ? (
          <Button className='next_btn bg-sky-600 hover:bg-slate-900' onClick={nextStep}>Next <FontAwesomeIcon className='ml-1' icon={faArrowRight} /></Button>
        ) : (
          <Button className='submit_btn font-bold bg-sky-600 hover:bg-slate-900' type="submit" onClick={handleSubmit}>Submit</Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default RegistrationForm;