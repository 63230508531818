// src/components/Checkbox.js
import React from 'react';

export const Checkbox = ({ className, checked, onChange, ...props }) => (
  <input
    type="checkbox"
    className={`form-checkbox h-5 w-5 text-sky-600 ${className}`}
    checked={checked}
    onChange={onChange}
    {...props}
  />
);