import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us - Reyansh"; // Set the title for Home Page
  }, []);
  return (
    <div className="text-slate-200 contact-us-container">
      <h1 className='font-semibold underline underline-offset-8 decoration-2 mb-12'>Contact Us</h1>
      <p className='text-sm xl:text-base'>If you have any questions or want to get in touch with us regarding anything, feel free to contact us:</p>

      <div className="contact-info">
        <h2>Email Us:</h2>
        <p>
          <a className='text-white' href="mailto:hindireyansh@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> hindireyansh@gmail.com</a>
        </p>

        
        {/* <p>Phone: +91-1234567890</p> */}
        <p className='text-sm'>5th Floor, Hindi Department, AIGS Block<br></br>
        Acharya Institute of Graduate Studies, Bangalore<br></br></p>
        <div className='text-xs pt-10 font-extralight italic'>Encountering any issues on the website? Reach out to <a href='mailto:hellodc18@gmail.com' className='font-light text-sky-500'>Deepak Choudhary</a> for assistance.</div>
      
      </div>
    </div>
  );
};

export default ContactUs;
