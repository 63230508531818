// EventDetails.js

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Example event data
const eventDetails = {
  1: {
    name: 'POSTER MAKING',
    rules: ['Each team consists of two participants.', 'Pick a famous writer and focus on their life, main works, themes, and influence.', 'The poster should include pictures that represent the writer work and impact.', 'Add important details like a short biography, their famous works, and memorable quotes, written clearly.','Use simple colours and easy-to-read fonts to make your poster look nice and clear.',],
    schedule: 'Date: 8th November, Time: 11:15 AM to 12:30 PM',
  },
  2: {
    name: 'MODEL MAKING',
    rules: ['Each team consists of 4 members, with all members contributing to their model.','Participants can choose any topic for their model, showcasing their creativity and skills. However, models with religious or political themes are not allowed, ensuring a focus on creativity and inclusivity.','Religious sites should be excluded from the designs, maintaining neutrality and respect for all viewpoints.','Teams should prepare their models ahead of time and bring them to the event for display and presentation. The model should be ready for judging and any explanations that might be required.','The judges decision will be final and cannot be contested.',],
    schedule: 'Date: 8th November, Time: 11:15 AM to 12:30 PM',
  },
  3: {
    name: 'EXTEMPORE',
    rules: ['Participants will be provided with a set of topic cards and will be asked to randomly select one card. They must then choose one topic from the selected card for their speech.','Participants will be given a limited amount of time (specified at the event) to prepare their speech on the chosen topic.','Each participant will deliver their speech individually on the stage, adhering to the given time limit.','Panel of judges will evaluate each speech based on pre-determined criteria, including content, delivery, and overall effectiveness.','The decision of the judging panel will be final and binding, and all participants are expected to respect their decision.'],
    schedule: 'Date: 8th November, Time: 11:45 AM to 12:45 PM',
  },
  4: {
    name: 'SHABD ANTAKSHARI',
    rules: ['Each team consists of two participants.','The coordinator starts the round by providing the first word.','Teams take turns responding with a word that begins with the last letter of the previous word given.','Each team has a maximum of 10 seconds to respond with a valid word.','Only Hindi words are allowed. Proper nouns (names of people, places) and abbreviations are strictly prohibited.','Words once used in the game cannot be repeated.','Participants are expected to maintain decorum and discipline. This includes respecting fellow participants, adhering to time limits, and refraining from interrupting others during the event.'],
    schedule: 'Date: 8th November, Time: 2:00 PM to 03:15 PM',
  },
  5: {
    name: 'DICTATION WRITING',
    rules: ['Individual Event.','Each participant must listen to the words read by the dictation leader and write it down word-by-word','Participants must ensure correct spelling, punctuation, and grammar while writing the dictated passage.','Participants must write legibly with neat, well-formed letters, maintaining proper alignment, consistent spacing, and accurate use of matras and punctuation within the given time limit.','The judges decision will be final, and participants are expected to respect the outcome without arguing or disputing the results',],
    schedule: 'Date: 8th November, Time: 02:30 PM to 03:30 PM',
  },
  6: {
    name: 'DUMB CHARADES',
    rules: ['Each team consists of four members.','One member from each team will be selected to act out a movie title given by the event coordinator.','The actor must convey the movie title using only gestures and body language.','Speaking, pointing to objects, or writing is strictly prohibited.',"Only the performer's team is allowed to guess the movie. No help from other team is allowed.",'If the team fails to guess within the time limit, they will not receive any points for that round.','A different team member must act in each round, ensuring everyone gets a chance to perform.','The use of any vulgar signs, gestures, or inappropriate language is strictly prohibited and may result in disqualification.'],
    schedule: 'Date: 8th November, Time: 03:30 PM to 04:45 PM',
  },
  7: {
    name: 'LOGO MAKING',
    rules: ['Participants must compete individually; team participation is not allowed.',"The participants have to create the logo on-spot on the given design statement.",'The logo must be submitted in digital format (JPEG/PNG).','The minimum size of the logo should be 1024x1024 pixels, with a 300 DPI resolution.','The design must be original and created solely by the participant. Copyright infringement or copying of other logos is strictly prohibited.','The participant may use tools like Illustrator, Figma, Photoshop, Canva etc.','The logo will be evaluated based on creativity, relevance to the theme, artistic quality, and innovation.','The Participant has to present the design process to the judges in about 2-3 minutes.','The decision of the judges will be final, and participants are expected to respect it.'],
    schedule: 'Date: 11th November, Time: 09:00 AM to 10:30 AM',
  },
 
  8: {
    name: 'ONLINE QUIZ',
    rules: ['Each participant must use their own laptop or mobile phone. Device sharing is strictly prohibited.','Questions will be projected on a main screen visible to all.','Answer choices will appear only on your individual device.','Each question has a set time limit. Answer quickly and accurately.','The decision of the judging panel will be final and binding, and all participants are expected to respect their decision.'],
    schedule: 'Date: 11th November, Time: 10:00 AM to 11:30 AM',
  },
  9: {
    name: 'SHABD MAHAL',
    rules: ['Individual participation, with each participant representing a team.','15 words will be displayed on the screen for 30 seconds. After 30 seconds, the words will disappear from the screen.','Participants must try to read and memorize as many words as possible during the display time.','Participants will have 1 minute to write down the words they remember on the provided answer sheet. The order in which the words are written does not matter.','Sharing answers with other participants is strictly prohibited. Any form of communication during the recall period will be considered a violation.','Writing an incorrect word may lead to disqualification.','All participants must maintain proper decorum and discipline throughout the game.','The judges decision is final and binding.'],
    schedule: 'Date: 11th November, Time: 11:00 AM to 12:15 PM',
  },
 
 
 
  10: {
    name: 'EMOJI PUZZLE',
    rules: ['Each team consists of two members','The event coordinator will display a series of emojis representing a common phrase or saying.','Teams have 30 seconds to discuss and come up with their guess.','Only one guess is allowed per team per round.','Sharing answers or discussing guesses with other teams is strictly prohibited.','All guesses must be respectful and family-friendly. Any inappropriate or offensive guesses will result in disqualification.','The team that correctly guesses the phrase first earns a point.','In case of multiple correct guesses, the fastest team will be awarded the point.','The judges decision is final and binding.'],
    schedule: 'Date: 8th November, Time: 01:30 PM to 02:30 PM',
  },
  11: {
    name: 'DEBATE',
    rules: ['Each team will have 2 members.','Each speaker will be given 3 minutes to present their argument. A bell will signal when the time is up.','No new arguments may be introduced during the closing statement. It should only summarize the points presented earlier.','Speakers must be allowed to speak without interruption. The opposing speaker should wait for their turn.','Personal attacks or abusive language are not permitted. The focus should remain on ideas and arguments, not individuals.','The coordinator will ensure smooth conduct and adherence to rules throughout the debate.','Audience members must refrain from disruptive or disrespectful behavior. Discipline must be maintained at all times.','Judging will be based on clarity, strength of arguments, use of evidence, presentation skills, and adherence to time limits.','The judges decision is final and binding.'],
    schedule: 'Date: 11th November, Time: 01:00 PM to 02:45 PM',
  },
 
  
  // Add more event details as needed
};

const EventDetails = () => {
  const { id } = useParams();
  const event = eventDetails[id];
  useEffect(() => {
    document.title = "Event Details - Reyansh"; // Set the title for Home Page
  }, []);

  return (
    <div className="p-6 sm:p-8 md:p-10 mt-24 mx-10 sm:mx-10 md:mx-auto my-8 my-28 sm:my-24 md:my-16 max-w-full md:max-w-2xl bg-white shadow-lg rounded-lg xl:my-32 lg:my-32">
      {event ? (
        <>
          <h1 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">{event.name}</h1>
          <h2 className="text-lg sm:text-xl font-semibold mb-2">Rules & Regulations</h2>
          <ul className="list-disc list-inside mb-4 pl-1 sm:mb-4">
            {event.rules.map((rule, index) => (
              <li key={index} className="text-gray-700 text-sm mb-2 sm:text-base" style={{ textIndent: '-1.4em', paddingLeft: '1.2em' }}>{rule}</li>
            ))}
          </ul>
          <h2 className="text-lg sm:text-xl font-semibold mb-2">Schedule</h2>
          <p className="text-gray-700 text-sm sm:text-base">{event.schedule}</p>
        </>
      ) : (
        <p className="text-sm sm:text-base">Event details not found.</p>
      )}
    </div>
  );
  
};

export default EventDetails;
