import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx'; 

const backendUrl = process.env.REACT_APP_REYANSH_BACKEND_URL;

const AdminPage = () => {
  const [registrations, setRegistrations] = useState([]);
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const eventsList = ['Poster Making','Model Making','Extempore', 'Shabd Antakshari', 'Dictation Writing', 'Dumb Charades','Shabd Mahal','Logo Making','Online Quiz','Emoji Paheli','Debate']; // Define your event list
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [loading,setloading] = useState(false);


  const navigate = useNavigate();
  
  const checkToken = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      
      // If token is missing, redirect to login page
      navigate('/admin/login');
      return;

    }
    
      console.log('Token found');
      console.log("fetching registrations");
      fetchRegistrations();
    
    

      
  };


  const flattenData = (data) => {
    return data.map((entry) => {
      // Create an object with common fields
      const flattenedEntry = {
        name: entry.name,
        email: entry.email,
        usn: entry.usn,
        auid: entry.auid,
        course: entry.course,
        semester: entry.semester,
        phoneNumber: entry.phoneNumber,
        day1Events: entry.day1Events.join(', '), // Convert array to string
        day2Events: entry.day2Events.join(', '), // Convert array to string
        teamName: entry.teamName,
        teamSize: entry.teamSize,
      };
  
      // Add team members dynamically to the flattened entry
      entry.teamMembers.forEach((member, index) => {
        flattenedEntry[`Member ${index + 1} Name`] = member.name;
        flattenedEntry[`Member ${index + 1} Phone`] = member.phone;
        flattenedEntry[`Member ${index + 1} USN`] = member.usn;
      });
  
      return flattenedEntry;
    });
  };

  const exportToExcel = () => {
    const flattenedData = flattenData(registrations); // Flatten the nested data
    const worksheet = XLSX.utils.json_to_sheet(flattenedData); // Convert to worksheet
    const workbook = XLSX.utils.book_new(); // Create a workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Registrations'); // Append sheet
    XLSX.writeFile(workbook, 'reyansh_registrations.xlsx'); // Trigger download
  };


  const LoadingScreen = () => {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Fetching data...</p>
      </div>
    );
  };

  useEffect(() => {
    // Check for the token when the component mounts
    checkToken();
  }, []);

  // Handle admin login
  const handleLogin = async (e) => {
    e.preventDefault();
    const storedToken = localStorage.getItem('token');
    
/*
    if (storedToken) {
      try {
        // Send the token to the backend for validation
        const tokenValidationResponse = await axios.get(`${backendUrl}/admin/validate-token`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
  
        if (tokenValidationResponse.status === 200) {
          // Token is valid, set authentication state and fetch registrations
          setIsAuthenticated(true);
          fetchRegistrations();
          return;
        }
      } catch (error) {
        console.error('Token validation failed, proceeding with login', error);
        localStorage.removeItem('token'); // Remove invalid token
      }
    }
*/
    // try {
    //   const response = await axios.post(`${backendUrl}/admin/login`, { username, password },{ withCredentials: true});
    //   if (response.status === 200) {
    //     localStorage.setItem('token', response.data.token);
    //     setIsAuthenticated(true);
    //     fetchRegistrations(); // Fetch registrations after successful login
    //   }
    // } catch (error) {
    //   console.error('Login failed', error);
    //   alert('Login failed: Invalid credentials');
    // }
  };

  // Fetch all registrations
  const fetchRegistrations = async () => {
    
    const token = localStorage.getItem('token');
    
    if (token) {
      
      try {
        console.log('Fetching registrations from backend');
        const response = await axios.get(`${backendUrl}/api/admin/registrations?events=${selectedEvents.join(',')}`, {
          headers: {
            Authorization: `Bearer ${token}`  // Send token in Authorization header
          }
        });

        if (response.status === 200) {
        setIsAuthenticated(true);
        
      }
        // console.log('Protected data:', response.data);
        
        setRegistrations(response.data)
      } catch (error) {
        
        console.error('Error fetching protected data', error);
        

      }
      
    } else {
      
      console.log('No token found');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/admin/login');
  };

  const handleCheckboxChange = (eventName) => {
    setSelectedEvents((prev) =>
      prev.includes(eventName)
        ? prev.filter((event) => event !== eventName) // Remove if unchecked
        : [...prev, eventName] // Add if checked
    );
    
    // fetchRegistrations();
    
  };

  useEffect(() => {
    fetchRegistrations();
  }, [selectedEvents]);
  

  return (
    <div className="p-6">
      {!isAuthenticated ? (
        navigate('/admin/login')
      ) : (
        <>
  <h2 className="text-white text-2xl font-bold mb-8 mt-8">User Registrations</h2>
  <div className=' mx-5 mb-5 flex justify-between'>
  
  <button
        className="border border-green-500 flex text-white px-5 py-3 rounded-lg"
        onClick={exportToExcel}
        disabled={registrations.length === 0}
      >
        Export to Excel
      </button>
      <button className='bg-red-700 text-white px-5 py-3 rounded-lg' onClick={logout}>Logout</button>
  </div>
  
  <div className='flex flex-wrap text-sm gap-5 mb-5'>
    {eventsList.map((event) => (
      <div key={event}>
        <input
          type="checkbox"
          value={event}
          onChange={()=>handleCheckboxChange(event)}
          id={`checkbox-${event}`}
        />
        <label className='text-white ml-1' htmlFor={`checkbox-${event}`}>{event}</label>
      </div>
    ))}
    
  </div>
  
  
  <div>
    
    {/* Render registrations here */}
  </div>
  {loading && <LoadingScreen />}
  <div className="overflow-x-auto" style={{  overflowY: 'auto' }}>
    <table className="min-w-full bg-white border-collapse border border-gray-200 " >
      <thead>
        <tr className="bg-gray-200">
        <th className="border border-gray-200 p-2">Sl No.</th>
          <th className="border border-gray-200 p-2">Name</th>
          <th className="border border-gray-200 p-2">Email</th>
          <th className="border border-gray-200 p-2">USN</th>
          <th className="border border-gray-200 p-2">AUID</th>
          <th className="border border-gray-200 p-2">Course</th>
          <th className="border border-gray-200 p-2">Semester</th>
          <th className="border border-gray-200 p-2">Phone Number</th>
          <th className="border border-gray-200 p-2">Team Name</th>
          <th className="border border-gray-200 p-2">Team Size</th>
          <th className="border border-gray-200 p-2">Team Members</th>
          <th className="border border-gray-200 p-2">Day 1 Events</th>
          <th className="border border-gray-200 p-2">Day 2 Events</th>
        </tr>
      </thead>
      <tbody>
        {registrations.map((registration, index) => (
          <tr key={registration._id}>
             <td className="border border-gray-200 p-2">{index + 1}</td>
            <td className="border border-gray-200 p-2">{registration.name}</td>
            <td className="border border-gray-200 p-2">{registration.email}</td>
            <td className="border border-gray-200 p-2">{registration.usn}</td>
            <td className="border border-gray-200 p-2">{registration.auid}</td>
            <td className="border border-gray-200 p-2">{registration.course}</td>
            <td className="border border-gray-200 p-2">{registration.semester}</td>
            <td className="border border-gray-200 p-2">{registration.phoneNumber}</td>
            <td className="border border-gray-200 p-2">{registration.teamName}</td>
            <td className="border border-gray-200 p-2">{registration.teamSize}</td>
            <td className="border border-gray-200 p-2">
              <ul>
                {registration.teamMembers.map((member, index) => (
                  <li key={index}>
                    {index + 1}. {member.name} ({member.phone}, {member.usn})
                  </li>
                ))}
              </ul>
            </td>
            <td className="border border-gray-200 p-2">
              <ul>
                {registration.day1Events.map((event, index) => (
                  <li key={index}>{event}</li>
                ))}
              </ul>
            </td>
            <td className="border border-gray-200 p-2">
              <ul>
                {registration.day2Events.map((event, index) => (
                  <li key={index}>{event}</li>
                ))}
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="mt-4 text-white">
        {registrations.length > 0 ? (
          <p>{registrations.length} registrations fetched.</p>
        ) : (
          <p>No data fetched.</p>
        )}
      </div>
  {loading && <LoadingScreen />}
</>

      )}
    </div>
  );
};

export default AdminPage;
